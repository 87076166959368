import React from "react";
import { Grid, Card, CardContent, Typography } from "@mui/material";

interface Feature {
  title: string;
  description: string;
}

interface FeaturesGridProps {
  features: Feature[];
}

const FeaturesGrid: React.FC<FeaturesGridProps> = ({ features }) => {
  return (
    <Grid container spacing={4}>
      {features.map((feature, index) => (
        <Grid item xs={12} sm={6} md={4} key={index} display="flex">
          <Card
            sx={{
              borderRadius: 2,
              boxShadow: "0 5px 15px rgba(0,0,0,0.15)",
            }}
          >
            <CardContent>
              <Typography
                variant="h6"
                component="div"
                fontWeight="bold"
                gutterBottom
              >
                {feature.title}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {feature.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default FeaturesGrid;
