import {
  Box,
  Chip,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import CoreCard from "./core/CoreCard";
import React from "react";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: "24px",
  borderRightWidth: 1,
  borderColor: "rgba(2,43,68, 0.3)",
  borderRightStyle: "solid",
  textAlign: "center",
  "&:last-child": {
    borderRight: "none",
  },
  backgroundColor: "transparent",
}));

const StyledTableRow = styled(TableRow)({
  "&:last-child td": {
    borderBottom: "none",
  },
});

const GradientHeaderCell = styled(StyledTableCell)(({ theme }) => ({
  background: `linear-gradient(45deg, #04668c 30%, #022B44 90%)`,
  color: theme.palette.common.white,
}));

const EnhancedChip = styled(Chip)({
  borderRadius: 12, // Increase the border radius for a rounded look.
  boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.1)", // A subtle shadow for the chip.
});

const EnhancedItemCell = styled(StyledTableCell)(({ theme }) => ({
  background: "linear-gradient(45deg, #04668c 30%, #022B44 90%)", // Adjusted gradient.
  boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.1)",
  "&:hover": {
    boxShadow: "0px 5px 8px rgba(0, 0, 0, 0.15)", // A deeper shadow on hover for interactivity.
  },
  color: "#fff",
}));

const FeaturesCell: React.FC<{ label: string }> = ({ label }) => {
  return (
    <StyledTableCell>
      <EnhancedChip
        label={
          <Typography fontFamily="Open Sans  Bold" variant="h6" fontWeight="bold">
            {label}
          </Typography>
        }
        color="secondary"
        sx={{ p: 2.5 }}
      />
    </StyledTableCell>
  );
};

const HeaderCell: React.FC<{ label: string; highlight?: boolean }> = ({
  label,
  highlight = false,
}) => {
  if (highlight) {
    return (
      <GradientHeaderCell>
        <Typography align="center" fontFamily="Open Sans Bold" color="white" variant="h4">
          {label}
        </Typography>
      </GradientHeaderCell>
    );
  }

  return (
    <StyledTableCell>
      <Typography align="center" fontFamily="Open Sans Semi Bold" variant="h4" color="primary">
        {label}
      </Typography>
    </StyledTableCell>
  );
};

const ItemCell: React.FC<{ label: string; highlight?: boolean }> = ({
  label,
  highlight = false,
}) => {
  if (highlight) {
    return (
      <EnhancedItemCell>
        <Typography align="center" fontFamily="Open Sans Bold">
          {label}
        </Typography>
      </EnhancedItemCell>
    );
  }
  return (
    <StyledTableCell>
      <Typography align="center" fontFamily="Open Sans Semi Bold" color="primary">
        {label}
      </Typography>
    </StyledTableCell>
  );
};

const ComparisonChart = () => {
  return (
    <CoreCard padding={0}>
      <Box
        sx={{
          overflow: "auto",
          maxWidth: "100%",
          width: "100%",
        }}
      >
        <Table>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell />
              <HeaderCell label="PredraftAI" highlight />
              <HeaderCell label="ChatGPT" />
              <HeaderCell label="JasperAI" />
            </StyledTableRow>
          </TableHead>
          <TableBody>
            <StyledTableRow>
              <FeaturesCell label="Research Capabilities" />
              <ItemCell
                label="Automatic Google search built-in for every article. Latest data and information."
                highlight
              />
              <ItemCell label="Limited up to 2021" />
              <ItemCell label="Limited up to 2021" />
            </StyledTableRow>
            <StyledTableRow>
              <FeaturesCell label="Tone Control" />
              <ItemCell
                label="Completely customizable and can be trained on existing content."
                highlight
              />
              <ItemCell label="Sounds like a robot. Overly cautious and apologetic." />
              <ItemCell label="Customizable but not trainable." />
            </StyledTableRow>
            <StyledTableRow>
              <FeaturesCell label="Factual Accuracy" />
              <ItemCell
                label="High accurate with citations and external links provided in-line."
                highlight
              />
              <ItemCell label="Routinely hallucinates false information to inaccurate results" />
              <ItemCell label="Requires you to provide topic research" />
            </StyledTableRow>
            <StyledTableRow>
              <FeaturesCell label="Ownership" />
              <ItemCell
                label="You own your output and can use it for any legal use. Plagiarism checker integrated."
                highlight
              />
              <ItemCell label="Can reproduce copyrighted works verbatim without warning." />
              <ItemCell label="You own the output and contains a plagiarism checker." />
            </StyledTableRow>
            <TableRow>
              <FeaturesCell label="Privacy" />
              <ItemCell
                label="All information entered is kept private and NOT provided to other users or competitors."
                highlight
              />
              <ItemCell label="Your data is used to train other models, unless you disable history completely." />
              <ItemCell label="Your data may be used to train other models." />
            </TableRow>
            <StyledTableRow>
              <FeaturesCell label="Revision" />
              <ItemCell
                label="Revise without arguing. Our prompts control the AI without letting it forget information."
                highlight
              />
              <ItemCell label="Frequently misinterprets prompts, breaking up your article. Limited context - frequently forgets earlier conversation." />
              <ItemCell label="Often requires chatting with Jasper - slower and more involved." />
            </StyledTableRow>
            <StyledTableRow>
              <FeaturesCell label="Bulk Creation" />
              <ItemCell
                label="Submit multiple articles at once while articles build in the background. Get an email when it's done."
                highlight
              />
              <ItemCell label="One prompt for one article at a time. Can't leave the page." />
              <ItemCell label="Supported but lacks end-to-end automated flow." />
            </StyledTableRow>
          </TableBody>
        </Table>
      </Box>
    </CoreCard>
  );
};

export default ComparisonChart;
