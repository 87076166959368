import { isProduction } from "../../utils/Environment";

interface Props {
  eventName: string;
  pixelId?: string;

  [key: string]: string;
}

function valueForEvent(eventName: string): number {
  if (eventName === "Purchase") {
    return 5;
  } else if (eventName === "Subscribe") {
    return 20;
  } else if (eventName === "AddToCart") {
    return 2;
  }
  return 0;
}

export default function FBPixel({
  eventName = "PageView",
  pixelId = "785035009782852",
  ...otherParams
}: Props) {
  if (isProduction()) {
    const qs = new URLSearchParams();
    qs.append("id", pixelId!);
    qs.append("ev", eventName);
    qs.append("cd[value]", valueForEvent(eventName).toString());
    qs.append("cd[currency]", "USD");
    Object.entries(otherParams).forEach(([key, value]) => {
      qs.append(key, value);
    });
    const url = `https://www.facebook.com/tr?${qs.toString()}`;
    return <img src={url} height="1" width="1" style={{ display: "none" }} />;
  }
  return undefined;
}
