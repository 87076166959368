import React, { useState } from "react";
import { Box, Card, CardContent, CardMedia, Modal, Typography } from "@mui/material";

interface ArticleExampleModalProps {
  coverImage: string;
  topic: string;
  description: string;
  children: React.ReactNode;
}

const ArticleExampleModal: React.FC<ArticleExampleModalProps> = ({
  coverImage,
  topic,
  description,
  children,
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Card
        sx={{
          width: "100%",
          height: "100%",
          cursor: "pointer",
          "&:hover": {
            boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)", // Change the shadow depth when hovered
          },
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          borderRadius: 2,
        }}
        onClick={handleOpen}
      >
        <CardMedia component="img" alt="Cover Image" height="140" image={coverImage} />
        <CardContent>
          <Box display="flex" flexDirection="column" height="100%" justifyContent="space-between">
            <Typography
              gutterBottom
              component="div"
              fontFamily="Open Sans Semi Bold"
              sx={{
                pb: 1,
                height: "3rem",
                overflow: "hidden", // In case the topic is too long
                textOverflow: "ellipsis", // Ellipsis for overflowing content
                display: "-webkit-box",
                WebkitLineClamp: 2, // Limit to 2 lines
                WebkitBoxOrient: "vertical",
              }}
            >
              {topic}
            </Typography>
            <Typography color="text.secondary">{description.substring(0, 200)}...</Typography>
          </Box>
        </CardContent>
      </Card>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "10%",
            left: "50%",
            transform: "translateX(-50%)",
            width: ["90vw", "80vw", "50vw", "50vw", "25vw"], // 80% of the viewport width
            maxHeight: "80vh", // 80% of the viewport height
            bgcolor: "background.paper",
            boxShadow: 24,
            px: 4,
            overflowY: "auto", // scrollable in Y direction if content overflows
            borderRadius: 2,
          }}
        >
          <>
            <CardMedia
              component="img"
              alt="Cover Image"
              image={coverImage}
              sx={{ my: 2, borderRadius: 2 }}
            />
            {children}
          </>
        </Box>
      </Modal>
    </>
  );
};

export default ArticleExampleModal;
