import React from "react";
import { styled, Typography } from "@mui/material";

interface GradientTextProps {
  text: string;
  variant?:
    | "inherit"
    | "button"
    | "caption"
    | "overline"
    | "body1"
    | "body2"
    | "subtitle1"
    | "subtitle2"
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6";
  startColor?: string;
  endColor?: string;
  display?: "block" | "inline";
  fontWeight?: "light" | "regular" | "medium" | "bold" | number;
  fontFamily?: string;
  align?: "left" | "center" | "right";
}

const GradientText: React.FC<GradientTextProps> = ({
  text,
  variant,
  startColor = "#66CCFF",
  endColor = "#022B44",
  display = "block",
  fontWeight = "regular",
  fontFamily = "Open Sans",
  align = "left",
}) => {
  const GradientTypography = styled(Typography)`
    background: linear-gradient(45deg, ${startColor}, ${endColor});
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: ${display};
    font-weight: ${fontWeight};
    font-family: ${fontFamily};
  `;

  return (
    <GradientTypography variant={variant || "body1"} align={align}>
      {text}
    </GradientTypography>
  );
};

export default GradientText;
