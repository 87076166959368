import React from "react";
import { Avatar, Box, Typography } from "@mui/material";

interface Props {
  imageUrl: string;
  name?: string;
  role: string;
  testimonial: string;
}

export default function SocialProof({ imageUrl, name, role, testimonial }: Props) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Avatar
        sx={{
          marginRight: "16px",
          width: "96px",
          height: "96px",
        }}
        src={imageUrl}
        alt={name || imageUrl}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="subtitle1" fontWeight="bold">
          {role}
        </Typography>
        {name && (
          <Typography variant="body2" color="textSecondary">
            {name}
          </Typography>
        )}
        <Typography variant="body1" fontStyle="italic">
          &quot;{testimonial}&quot;
        </Typography>
      </Box>
    </Box>
  );
}
