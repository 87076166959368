import React, { ReactNode } from "react";
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CoreCard from "@/components/core/CoreCard";
import SecurityIcon from "@mui/icons-material/Security";
import BiotechIcon from "@mui/icons-material/Biotech";
import SettingsIcon from "@mui/icons-material/Settings";
import SpeedIcon from "@mui/icons-material/Speed";
import CoreLoadingButton from "../core/CoreLoadingButton";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Link from "next/link";
import { getSignUpHref } from "../../const/Hrefs";

function FeatureList({
  title,
  icon,
  features,
}: {
  title: string;
  icon?: ReactNode;
  features: string[];
}) {
  return (
    <Box>
      <Stack direction="row" alignItems="center" spacing={1} justifyContent="center" sx={{ mb: 1 }}>
        {icon}
        <Typography variant="h6" color="primary" fontFamily="Open Sans Semi Bold">
          {title}
        </Typography>
      </Stack>

      <List>
        {features.map((feature, idx) => (
          <ListItem key={idx}>
            <ListItemIcon>
              <CheckCircleIcon color="secondary" fontSize="medium" />
            </ListItemIcon>
            <ListItemText primary={feature} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

const FeatureShowcase2 = () => {
  const featureGroups = [
    {
      title: "Customization and Personalization",
      icon: <SettingsIcon />,
      features: [
        "Control word length up to 2000 words",
        "Personalize tone - ensure content doesn't sound robotic",
        "Automatic product upsells within content",
        "Edit article outline to control H2s and facts within each section",
        "Fast revisions to existing articles",
      ],
    },
    {
      title: "Deeper research on your topic",
      icon: <BiotechIcon />,
      features: [
        "Real-time Google Search integration",
        "Information after 2021 (live Google searches)",
        "Fact citations & external links to real websites",
        "Related keyword (LSI) generation",
        "Custom research links",
        "Block competitor domains",
      ],
    },
    {
      title: "Productivity",
      icon: <SpeedIcon />,
      features: [
        "Publish to WordPress directly",
        "Create multiple articles in the background",
        "Automatic external link embedding",
        "Article translations in 12 languages",
        "Website scan for content suggestions",
        "Use GPT-4",
      ],
    },
    {
      title: "Trust & Security",
      icon: <SecurityIcon />,
      features: [
        "Control and change citations and external links",
        "Integrated plagiarism checker",
        "Privacy assurance: user data and content data not shared",
      ],
    },
  ];

  return (
    <Grid container spacing={[2, 2, 4, 4]}>
      {featureGroups.map((group, idx) => (
        <Grid key={idx} item xs={12} sm={6}>
          <CoreCard key={idx}>
            <Stack justifyContent="space-between">
              <FeatureList title={group.title} icon={group.icon} features={group.features} />
              <Box display="flex" justifyContent="center">
                <Link href={getSignUpHref()}>
                  <CoreLoadingButton
                    text="Get Started"
                    variant="text"
                    onClick={() => {}}
                    endIcon={<ArrowForwardIcon fontSize="small" />}
                  />
                </Link>
              </Box>
            </Stack>
          </CoreCard>
        </Grid>
      ))}
    </Grid>
  );
};

export default FeatureShowcase2;
